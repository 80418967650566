import { List } from '@material-ui/core'
import Button, { ButtonProps } from '@material-ui/core/Button'
import Dialog, { DialogProps } from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import React, { FunctionComponent } from 'react'
import BlackDollarIcon from '../../assets/icons/Money/black-dollar.svg'
import SubInfoDollarIcon from '../../assets/icons/Money/subinfo-dollar.svg'
import IconText from '../../components/Typography/IconText'

/**
 * # Suggestion
 *
 * ## Thin Wrapper Policy
 * - We should try to make our components as thin as possible a wrapper to reduce complexity
 * and interoperability issues.
 *
 * ## Sectional Parameters Policy
 * - Sections can either take a React component or parameters for JSX interpolation.
 * - If a React component is provided, it will replace the entire section and the parameters will be ignored.
 */

export interface CommonDialogProps extends DialogProps {
  component?: FunctionComponent
  sections?: {
    header?: {
      component?: FunctionComponent
      title?: string
      rightText?: string
      isCustomRightImg?: boolean
      customRightImg?: any
      isAmount?: boolean
      children?: any
      dynamic?: any
      custom?: any
      style?: any
      infoLine?: FunctionComponent | string
      rightInfoLine?: any
      infoLineIsAmount?: boolean
      actions?: {
        displayText?: string
        props?: ButtonProps
      }[]
    }
    bodyNoPadding?: boolean
    body?: FunctionComponent
    leftFooterIcon?: any
    footer?: {
      component?: FunctionComponent
      custom?: any
      actions?: {
        displayText?: string
        props?: ButtonProps
      }[]
    }
  }
}

export const CommonDialog = (props: CommonDialogProps) => {
  /* const classes = useStyles() */

  return (
    <Dialog {...props /* thin wrapper policy, hence just `...props` */}>
      {props?.component?.({}) || (
        <>
          {props?.sections?.header?.component?.({})}
          {(props?.sections?.header?.children ||
            props?.sections?.header?.dynamic) && (
            <DialogTitle className="dialog-title">
              {props?.sections?.header?.children ? (
                <List className="core-list subheader">
                  {props?.sections?.header?.children}
                </List>
              ) : null}
              {props?.sections?.header?.dynamic ? (
                <>{props?.sections?.header?.dynamic}</>
              ) : null}
            </DialogTitle>
          )}
          {props?.sections?.header?.title && (
            <DialogTitle className="dialog-title">
              {props?.sections?.header?.title ? (
                <List className="core-list subheader">
                  <div className="dialog-dynamic-content">
                    <div className="session">
                      <div className="flex session">
                        <div
                          className="title flex-space"
                          style={props?.sections?.header?.style}
                        >
                          {props?.sections?.header?.title}
                        </div>
                        {props?.sections?.header?.rightText && (
                          <div className="rightText">
                            {props?.sections?.header?.isAmount ? (
                              <IconText
                                icon={
                                  <img
                                    src={BlackDollarIcon}
                                    style={{ width: '12px' }}
                                  />
                                }
                                font="highlight-text xxTitle rightText"
                                // childrenStyle={{ color: '#ff9800' }}
                                children={props?.sections?.header?.rightText}
                              />
                            ) : (
                              <span className="flex-space">
                                {props?.sections?.header?.rightText}
                              </span>
                            )}
                          </div>
                        )}
                        {props?.sections?.header?.isCustomRightImg &&
                          props?.sections?.header?.customRightImg}
                      </div>
                    </div>
                  </div>
                </List>
              ) : null}
              {props?.sections?.header?.infoLine ? (
                <div className="infoline-content" style={{ flex: '1' }}>
                  <span
                    className="infoline mdDesc flex-space"
                    style={{ fontWeight: 700, fontSize: '12px' }}
                  >
                    {props?.sections?.header?.infoLine}
                  </span>
                  {props?.sections?.header?.rightInfoLine && (
                    <>
                      {props?.sections?.header?.infoLineIsAmount ? (
                        <IconText
                          icon={
                            <img
                              src={SubInfoDollarIcon}
                              style={{ width: '12px' }}
                            />
                          }
                          font="infoline mdDesc rightText"
                          children={props?.sections?.header?.rightInfoLine}
                        />
                      ) : (
                        <span className="infoline mdDesc rightText">
                          {props?.sections?.header?.rightInfoLine}
                        </span>
                      )}
                    </>
                  )}
                </div>
              ) : null}
            </DialogTitle>
          )}
          {props?.sections?.header?.custom}
          <DialogContent
            className={`dialog-content ${
              props?.sections?.bodyNoPadding ? 'no-margin' : null
            }`}
          >
            {props?.sections?.body?.({})}
          </DialogContent>
          {props?.sections?.footer?.custom}
          <DialogActions className="dialog-footer">
            {props?.sections?.leftFooterIcon}
            {props?.sections?.footer.component?.({}) ||
              props?.sections?.footer.actions.map((v, i) => (
                <Button
                  variant="contained"
                  size="small"
                  className="btn-dialog btn-primary"
                  style={
                    v?.displayText === 'Approve'
                      ? { backgroundColor: '#00bf0f', color: '#ffffff' }
                      : v?.displayText?.includes('Cancel') ||
                        v?.displayText?.includes('Close')
                      ? {
                          outline: 'auto',
                          backgroundColor: '#FFFFFF',
                          color: '#FF9800',
                        }
                      : {}
                  }
                  key={i}
                  {...v?.props}
                >
                  {v?.displayText}
                </Button>
              ))}
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}
