import { StandardDialog } from '@ifca-root/react-component/src/components/Dialog/StandardDialog';
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { InputUpload } from '@ifca-root/react-component/src/components/Input/InputUpload';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import SnackBarMsg from '@ifca-root/react-component/src/components/SnackBar/SnackBarMsg';
import { List, ListItem, ListItemText } from '@material-ui/core';
import {
  useCreateBatchUomMutation,
  useGetUomQuery,
} from '../../../generated/graphql';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import * as XLSX from 'xlsx';
import { IUOMForm } from './UOMListing';

export const UploadValidationListing = (props: any) => {
  let history = useHistory();
  const { state }: any = useLocation();
  const { mode } = props;
  const { mode: pageMode }: any = useParams();
  let user = JSON.parse(localStorage.getItem('loggedInUser'));
  let dateFormat = require('dateformat');

  const [template, setTemplate] = useState([]);
  const [templateName, setTemplateName] = useState('');
  const [rawData, setRawData] = useState([]);
  const [openUpload, setOpenUpload] = useState(false);
  const [payrollCycleID, setPayrollCycleID] = useState('');
  const [upload, setUpload] = useState('');
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [isRevalidate, setIsRevalidate] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const {
    data: { getUOM, uomDate } = {
      getUOM: [],
      uomDate: null,
    },
    loading,
  } = useGetUomQuery({
    fetchPolicy: 'network-only',
    variables: {
      orderByAsc: 'name',
    },
  });

  const [createBatchUOM, error] = useCreateBatchUomMutation({
    onError: error => {
      snackFunc(error.message, 1000);
    },
    onCompleted: data => {
      if (data.createBatchUOM === true) {
        snackFunc('Inserted Successfully!', 1000);
        setOpenConfirmDialog(false);
        history.push(`/common-settings/uom`);
      } else {
        snackFunc('Insert Fail!', 1000);
      }
    },
  });

  const onSelectFile = (event: any) => {
    let IsValid: boolean = false;
    let excelFile: File = event.target.files[0];
    let nextState = [...rawData];
    let reader: any = new FileReader();
    if (excelFile) {
      IsValid =
        excelFile.type === 'application/vnd.ms-excel' ||
        excelFile.type ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          ? true
          : false;
    }

    if (IsValid === true) {
      nextState.splice(0, nextState.length);
      setUpload(excelFile.name);
      reader.onload = function(event) {
        var data = event.target.result;
        var workBook = XLSX.read(data, { type: 'array' });
        var sheetName = workBook.SheetNames[0];
        var rowObj = XLSX.utils.sheet_to_json(workBook.Sheets[sheetName], {
          raw: false,
        });

        rowObj.forEach(Obj => {
          let Transaction: IUOMForm = {
            name: null,
            code: null,
          };

          for (const [key, value] of Object.entries(Obj)) {
            if (key === 'name') Transaction.name = value;
            if (key === 'code') Transaction.code = value;
          }
          nextState.push(Transaction);
        });
        setRawData(nextState);
      };
      reader.readAsArrayBuffer(excelFile);
    } else {
      snackFunc('Invalid file type!', 1000);
      return false;
    }
  };

  const onUploadTemplate = () => {
    let Transaction: any[] = [...rawData];
    let existingUOM: any[] = [...getUOM];

    let Items: any[] = [];

    if (template.length < 1) {
      snackFunc('Excel file is required', 1000);
      return;
    }

    for (var i = 0; i < Transaction.length; i++) {
      let uomName: string = Transaction[i].name.toString().toUpperCase();
      let uomCode: string = Transaction[i].code.toString().toUpperCase();

      let Errors: any[] = [];

      if (Transaction[i].name === null && Transaction[i].code === null) {
        Errors.push('Amount or Quantity is required');
      }
      let a = existingUOM.map(j => ({
        newName: j.name.toString().toUpperCase(),
        newCode: j.code.toString().toUpperCase(),
      }));

      let IsDuplicate: boolean =
        a.filter(k => k.newName === uomName && k.newCode === uomCode).length >=
        1
          ? true
          : false;

      if (IsDuplicate === true) {
        Errors.push('Duplicated Record! Please try again');
      }

      Items.push({
        name: Transaction[i].name,
        code: Transaction[i].code,
        Errors: Errors,
        IsValid: Errors.length > 0 ? false : true,
      });
    }
    let nextState = [...template];
    nextState.splice(0, nextState.length);
    nextState = Items;
    setTemplate(nextState);
    setTemplateName(upload);
    setIsRevalidate(true);
    setRawData([]);
    setOpenUpload(false);
    setUpload('');
  };

  const onSubmit = () => {
    let Items: any[] = [];
    let UOM: any[] = getUOM;
    template.forEach(i => {
      let uomName: string = i.name.toString().toUpperCase();
      let uomCode: string = i.code.toString().toUpperCase();

      let uomObj: any = UOM?.filter(
        j =>
          j?.name?.toString()?.toUpperCase() === uomName &&
          j?.code.toString()?.toUpperCase() === uomCode,
      )[0];

      Items.push({
        name: i?.name,
        code: i?.code,
        commonStatus: 'ACTIVE',
        ExcelFileName: templateName,
      });
    });

    createBatchUOM({
      variables: {
        input: Items,
      },
    });
  };

  const snackFunc = (showText: string, ms: number) => {
    setSnackBarMessage(showText);
    setOpenSnackBar(true);
    setTimeout(() => {
      setSnackBarMessage('');
      setOpenSnackBar(false);
    }, ms);
  };

  useEffect(() => {
    if (isRevalidate === false) {
      setTemplate(state?.template);
      setTemplateName(state?.fileName);
    }
  }, [isRevalidate]);

  useEffect(() => {
    let Confirm: boolean =
      template.filter(i => i.IsValid === false).length > 0 ? false : true;
    setIsConfirm(Confirm);
  }, [template]);

  return (
    <>
      {console.log(state)}
      <MainHeader
        mainBtn="back"
        smTitle="System Admin"
        title={user?.accountName}
        onClick={() => history.push(`/common-settings/uom`)}
        routeSegments={[
          { name: 'System Admin' },
          { name: 'Upload Template', current: true },
        ]}
        rightRouteSegments={[{ name: 'Validation' }]}
      />

      <ContentWrapper>
        {template.filter(i => i.IsValid === false).length > 0 ? (
          <>
            <span className="xsTitle">
              Problem (
              <span className="highlight-text">
                {template.filter(i => i.IsValid === false).length}
              </span>
              )
            </span>
          </>
        ) : (
          <></>
        )}
        <List className="core-list">
          {template
            .filter(i => i.IsValid === false)
            .map((i, index) => (
              <ListItem key={index}>
                <ListItemText
                  primary={
                    <>
                      <span className="xsTitle">{i.code}</span>
                    </>
                  }
                  secondary={
                    <>
                      <span className="desc extra-row">
                        <span>{i.name}</span>
                      </span>

                      <span className="desc extra-row" style={{ color: 'red' }}>
                        {i.Errors.map((j, jindex) => (
                          <>
                            <span>{j}&nbsp;</span>
                            <span>
                              {jindex + 1 < i.Errors.length ? '&' : ''}
                            </span>{' '}
                            &nbsp;
                          </>
                        ))}
                      </span>
                    </>
                  }
                />
              </ListItem>
            ))}
        </List>

        {template.filter(i => i.IsValid === true).length > 0 && (
          <span className="xsTitle">
            Validated (
            <span className="highlight-text">
              {template.filter(i => i.IsValid === true).length}
            </span>
            )
          </span>
        )}
        <List className="core-list">
          {template
            .filter(i => i.IsValid === true)
            .map((i, index) => (
              <ListItem key={index}>
                <ListItemText
                  primary={<span className="xsTitle">{i.code}</span>}
                  secondary={
                    <span className="desc extra-row">
                      <span>{i.name}</span>
                    </span>
                  }
                />
              </ListItem>
            ))}
        </List>
      </ContentWrapper>

      <SnackBarMsg open={openSnackBar} message={snackBarMessage} />

      <StandardDialog
        fullWidth={true}
        open={openUpload}
        onClose={() => setOpenUpload(false)}
        sections={{
          header: {
            title: 'Upload Template',
          },
          body: () => (
            <>
              <InputUpload
                accept={'.xlsx, .xls, .cxv'}
                value={upload}
                label="File"
                required
                handleUpload={e => onSelectFile(e)}
                className="p-b-20"
              />
            </>
          ),
          footer: {
            actions: [
              {
                displayText: 'Cancel',
                props: {
                  onClick: () => [
                    setOpenUpload(false),
                    setUpload(''),
                    //setPayrollCycleID(''),
                    setRawData([]),
                  ],
                  variant: 'contained',
                  color: 'primary',
                },
              },
              {
                displayText: 'Save',
                props: {
                  onClick: () => [onUploadTemplate()],
                  variant: 'contained',
                  color: 'primary',
                  disabled: rawData.length < 1,
                },
              },
            ],
          },
        }}
      />

      <StandardDialog
        fullWidth={true}
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        sections={{
          header: {
            title: 'Upload Confirmation',
          },
          body: () => <>Are you sure to Upload ? </>,
          footer: {
            actions: [
              {
                displayText: 'Cancel',
                props: {
                  onClick: () => setOpenConfirmDialog(false),
                  variant: 'contained',
                  color: 'primary',
                },
              },
              {
                displayText: 'Confirm',
                props: {
                  onClick: () => {
                    onSubmit();
                  },
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />

      <Footer
        options={[
          {
            onClick: () =>
              isConfirm ? [setOpenConfirmDialog(true)] : [setOpenUpload(true)],
            name: isConfirm ? 'Confirm Upload' : 'Re-upload',
            color: 'primary',
          },
        ]}
      />
    </>
  );
};
