import React, { lazy } from 'react';

const ProAndAuthListing = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/SystemAdminModule/AccessSecurityModule/ProjectRoleAuthModule/ProAndAuthListing'
  ).then(module => ({
    default: module.ProAndAuthListing,
  })),
);

const ProAndAuthRoleAssignmentListing = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/SystemAdminModule/AccessSecurityModule/ProjectRoleAuthModule/ProAndAuthRoleAssignmentListing'
  ).then(module => ({
    default: module.ProAndAuthRoleAssignmentListing,
  })),
);
const ProAndAuthRoleForm = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/SystemAdminModule/AccessSecurityModule/ProjectRoleAuthModule/ProAndAuthRoleForm'
  ).then(module => ({
    default: module.ProAndAuthRoleForm,
  })),
);

export const projectRoleAuthRoutes = [
  {
    props: {
      exact: true,
      path: '/:mode/general-settings/access-security/project-auth',
    },
    component: <ProAndAuthListing software="Site" />,
  },

  {
    props: {
      exact: true,
      path:
        '/:mode/general-settings/access-security/project-auth/:contractID/role-assignment',
    },
    component: <ProAndAuthRoleAssignmentListing software="Site" />,
  },
  {
    props: {
      exact: true,
      path:
        '/:mode/general-settings/access-security/project-auth/:contractID/role-assignment/:roleID/form',
    },
    component: <ProAndAuthRoleForm software="Site" />,
  },
];
