import React, { lazy } from 'react';

const UserListing = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/SystemAdminModule/AccessSecurityModule/UserModule/UserListing'
  ).then(module => ({
    default: module.UserListing,
  })),
);
const UserDetail = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/SystemAdminModule/AccessSecurityModule/UserModule/UserDetail'
  ).then(module => ({
    default: module.UserDetail,
  })),
);

const UserForm = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/SystemAdminModule/AccessSecurityModule/UserModule/UserForm'
  ).then(module => ({
    default: module.UserForm,
  })),
);

export const userRoutes = [
  //USERLIST
  {
    props: {
      exact: true,
      path: '/:mode/general-settings/access-security/user',
    },
    component: <UserListing />,
  },
  //USER Detail
  {
    props: {
      exact: true,
      path: '/:mode/general-settings/access-security/user/detail/:userID',
    },
    component: <UserDetail />,
  },

  {
    props: {
      exact: true,
      path: '/:mode/general-settings/access-security/user/add',
    },
    component: <UserForm mode={'add'} />,
  },

  {
    props: {
      exact: true,
      path: '/:mode/general-settings/access-security/user/edit/:userID',
    },
    component: <UserForm mode={'edit'} />,
  },
];
