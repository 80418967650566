import React, { lazy } from 'react';

const RolesListing = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/SystemAdminModule/AccessSecurityModule/RolesModule/RolesListing'
  ).then(module => ({
    default: module.RolesListing,
  })),
);
const RolesFormv2 = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/SystemAdminModule/AccessSecurityModule/RolesModule/RolesForm'
  ).then(module => ({
    default: module.RolesForm,
  })),
);

const RolesDetail = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/SystemAdminModule/AccessSecurityModule/RolesModule/RolesDetail'
  ).then(module => ({
    default: module.RolesDetail,
  })),
);

export const rolesRoutes = [
  //ROLESLISTING
  {
    props: {
      exact: true,
      path: '/:mode/general-settings/access-security/role',
    },
    component: <RolesListing software="Site" />,
  },
  //ROLESFORM
  // {
  //   props: {
  //     exact: true,
  //     path: '/:mode/general-settings/access-security/role/test',
  //   },
  //   component: <RolesForm mode="add" />,
  // },
  // {
  //   props: {
  //     exact: true,
  //     path: '/:mode/general-settings/access-security/role/:id/testedit',
  //   },
  //   component: <RolesForm mode="edit" />,
  // },
  {
    props: {
      exact: true,
      path: '/:mode/general-settings/access-security/role/add',
    },
    component: <RolesFormv2 mode="add" software="Site" />,
  },
  {
    props: {
      exact: true,
      path: '/:mode/general-settings/access-security/role/:id/edit',
    },
    component: <RolesFormv2 mode="edit" software="Site" />,
  },

  //ROLES Detail
  {
    props: {
      exact: true,
      path: '/:mode/general-settings/access-security/role/detail/:id',
    },
    component: <RolesDetail software="Site" />,
  },
];
