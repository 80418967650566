import { ApolloProvider } from '@apollo/react-common';
import { InstallBanner } from '@contract-root/admin-react/src/components/InstallBanner/InstallBanner';
import { useInstallPrompt } from '@contract-root/admin-react/src/helpers/Hooks/useInstallPrompt';
import DateFnsUtils from '@date-io/date-fns';
import '@ifca-root/react-component/src/assets/styles/app.scss';
import theme from '@ifca-root/react-component/src/assets/theme';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import SnackBarMsg from '@ifca-root/react-component/src/components/SnackBar/SnackBarMsg';
import {
  Backdrop,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers/';
import { setAccessToken } from 'AccessToken';
import { ContractClient, siteNodeRefreshUrl } from 'SiteClient';
import 'assets/styles/sitex.scss';
import Layout from 'components/Layout/Layout';
import { useLogoutMutation } from 'generated/graphql';
import { useBroadcastChannel } from 'helpers/Hooks/useBroadcastChannel';
import { useServiceWorker } from 'helpers/Hooks/useServiceWorker';
import { createBrowserHistory } from 'history';
import localForage from 'localforage';
import React, { Suspense, useEffect, useReducer, useState } from 'react';
import { Router } from 'react-router-dom';
import Routes from './Router/Routes';
import AppContext from './Store/AppContext';
import { GlobalInitialState, RootReducer } from './Store/RootReducer';
import SnackBarContext from './Store/SnackBarContext';

export const history = createBrowserHistory();

const App = () => {
  const [globalState, dispatch] = useReducer(RootReducer, GlobalInitialState);
  const [loading, setLoading] = useState(true);
  const { isUpdateAvailable, updateAssets } = useServiceWorker();
  const { userChannel } = useBroadcastChannel();
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);
  const [snackBarMsg, setSnackBarMsg] = useState<string>('');
  const key = sessionStorage.getItem('tokenKey');
  const user = JSON?.parse(localStorage?.getItem('loggedInUser'));
  const routing: any = history?.location?.pathname;

  const [logout, { client }] = useLogoutMutation({
    client: ContractClient,
  });

  const pathBeforeLogin = [
    '/authentication/404',
    '/reset-password/:token',
    '/user/activate/:token',
    '/user/create-password/:token',
    '/login',
    '/forgot-password',
  ];

  const isNotMainContent = () => {
    return pathBeforeLogin?.filter(v => routing?.includes(v))?.length > 0;
  };

  const userLogout = async (userID?: string, entityID?: string) => {
    await logout({ variables: { ID: userID, entityID: entityID } });
    setAccessToken('');
    await client!.resetStore();
  };

  useEffect(() => {
    fetch(siteNodeRefreshUrl, {
      method: 'POST',
      credentials: 'include',
    }).then(async x => {
      const { accessToken } = await x?.json();
      setAccessToken(accessToken);
      setLoading(false);
    });
    userChannel.onmessage = (data: any) => {
      if (data?.payload?.type === 'SIGN_OUT' && data?.userId === user?.ID) {
        userLogout(data?.userId, data?.entityID);
        localStorage?.removeItem('loggedInUser');
        localForage?.removeItem('permission');
        sessionStorage?.removeItem('tokenKey');
        history.push('/login');
      }
    };
  }, []);

  useEffect(() => {
    if (!!!key && !isNotMainContent()) {
      // userLogout(user?.ID);
      // localStorage?.removeItem('loggedInUser');
      // localForage?.removeItem('permission');
      // sessionStorage?.removeItem('tokenKey');
      history.push('/login');
    }
    if (
      (user == null || Object?.keys(user)?.length == 0) &&
      !isNotMainContent()
    ) {
      // localStorage?.removeItem('loggedInUser');
      // localForage?.removeItem('permission');
      // sessionStorage?.removeItem('tokenKey');
      history.push('/login');
    }
  }, [key, user]);

  const { promptable, promptToInstall, isInstalled } = useInstallPrompt();
  const [isVisible, setVisible] = useState(false);

  const hide = () => setVisible(false);

  if (loading) {
    return <Loading />;
  }

  return (
    <AppContext.Provider value={{ globalState, dispatch }}>
      <SnackBarContext.Provider value={{ setOpenSnackBar, setSnackBarMsg }}>
        <ApolloProvider client={ContractClient}>
          <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Router history={history}>
                <Layout>
                  <Suspense fallback={<Loading />}>
                    <Routes />
                    <Backdrop open={isUpdateAvailable}>
                      <Dialog
                        open={isUpdateAvailable}
                        // onClose={() => setReloadDia(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                      >
                        <DialogTitle id="alert-dialog-title">
                          {'New Update Available'}
                        </DialogTitle>
                        <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                            Please update CX Site Management to the latest
                            version to continue.
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={updateAssets} autoFocus>
                            Update
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </Backdrop>
                    <SnackBarMsg
                      open={openSnackBar}
                      setOpen={setOpenSnackBar}
                      message={snackBarMsg}
                    />
                  </Suspense>
                </Layout>
                {promptable && !isInstalled ? (
                  <InstallBanner
                    ButtonOption={{
                      section: {
                        props: {
                          style: { display: !isVisible ? 'none' : null },
                        },
                      },
                      option1: { props: { onClick: () => hide() } },
                      option2: { props: { onClick: () => promptToInstall() } },
                    }}
                  />
                ) : null}
              </Router>
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </ApolloProvider>
      </SnackBarContext.Provider>
    </AppContext.Provider>
  );
};
export default App;
