import React, { lazy } from 'react';
import { SubordinateListing } from './Subordinate/SubordinateListing';

const MorningBriefingListing = lazy(() =>
  import('./MorningBriefing/MorningBriefingListing').then(module => ({
    default: module.MorningBriefingListing,
  })),
);

const IncidentListing = lazy(() =>
  import('./Incident/IncidentListing').then(module => ({
    default: module.IncidentListing,
  })),
);

const SafetyListing = lazy(() =>
  import('./Safety/SafetyListing').then(module => ({
    default: module.SafetyListing,
  })),
);

const IncidentForm = lazy(() =>
  import('./Incident/IncidentForm').then(module => ({
    default: module.IncidentForm,
  })),
);

const MorningBrifiengForm = lazy(() =>
  import('./MorningBriefing/MorningBriefingForm').then(module => ({
    default: module.MorningBriefingForm,
  })),
);

const siteDiaryRoutes = [
  {
    props: { exact: true, path: '/morning-briefing' },
    component: <MorningBriefingListing />,
  },
  {
    props: { exact: true, path: '/morning-briefing/:siteID' },
    component: <MorningBriefingListing />,
  },
  {
    props: { exact: true, path: '/incident' },
    component: <IncidentListing />,
  },
  {
    props: { exact: true, path: '/incident/:siteID' },
    component: <IncidentListing />,
  },
  {
    props: { exact: true, path: '/incident/incidentform/add' },
    component: <IncidentForm mode={'add'} />,
  },
  {
    props: { exact: true, path: '/morning-briefing/morningbriefingform/add' },
    component: <MorningBrifiengForm mode={'add'} />,
  },
  {
    props: { exact: true, path: '/safety' },
    component: <SafetyListing />,
  },
  {
    props: { exact: true, path: '/safety/:siteID' },
    component: <SafetyListing />,
  },
];

export default siteDiaryRoutes;
