import React, { lazy } from 'react';
import accessSecurityRoutes from './RoutesModule/AccessSecurityRoutes';
import companyLogoRoutes from './RoutesModule/CompanyLogoRoutes';
import projectSiteRoutes from './RoutesModule/ProjectSiteRoutes';
import subcontractorSetupRoutes from './RoutesModule/SubcontractorSetupRoutes';
import uomRoutes from './RoutesModule/UOMRoutes';

//System Admin//
const SystemAdminSubmenu = lazy(() =>
  import('./SystemAdminSubmenu').then(module => ({
    default: module.SystemAdminSubmenu,
  })),
);

const UOMExchangeListing = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/SystemAdminModule/UOMExchange/UOMRateListing'
  ).then(module => ({
    default: module.UOMRateListing,
  })),
);

const WatermarkLogoPreview = lazy(() =>
  import(
    '@contract-root/admin-react/src/containers/SystemAdminModule/WatermarkLogoModule/WatermarkLogoPreview'
  ).then(module => ({
    default: module.WatermarkLogoPreview,
  })),
);

const systemAdminRoutes = [
  {
    props: { exact: true, path: '/system-admin' },
    component: <SystemAdminSubmenu />,
  },
  {
    props: { exact: true, path: '/:mode/general-settings/uom-conversion' },
    component: <UOMExchangeListing />,
  },
  {
    props: {
      exact: true,
      path: `/:mode/general-settings/company-logo/watermark`,
    },
    component: <WatermarkLogoPreview />,
  },
  ...accessSecurityRoutes,
  ...projectSiteRoutes,
  ...subcontractorSetupRoutes,
  ...companyLogoRoutes,
  ...uomRoutes,
  ...subcontractorSetupRoutes,
];

export default systemAdminRoutes;
