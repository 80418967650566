import React, { lazy } from 'react';

export const SITE_WAGES = 'site-wages/submenu';
export const DAILY_REGISTER = 'daily-registered-work-hours';
export const DAILY_WAGES = 'daily-wages-verification';
export const WAGES_BOOK = 'wages-book';
export const WAGES_BOOK_DETAIL = 'book-detail';
export const WAGE_CYCLE = 'wage-cycle-closure';
export const WAGE_CYCLE_FORM = 'wage-cycle-closure-form';
export const EMPLOYEE_LIST = 'employee-list';
export const EMPLOYEE = 'worker';
export const ATTENDANCE = 'worker-attendance';

const SiteWagesSubmenu = lazy(() =>
  import('./SiteWagesSubmenu').then(module => ({
    default: module.SiteWagesSubmenu,
  })),
);

const WagesDailyRegisterWorkHoursList = lazy(() =>
  import('./DailyRegisteredWorkHours/DailyRegisteredWorkHoursList').then(
    module => ({
      default: module.DailyRegisteredWorkHours,
    }),
  ),
);

const WagesDailyWagesVerificationList = lazy(() =>
  import('./DailyWagesVerification/DailyWagesVerificationList').then(
    module => ({
      default: module.DailyWagesVerificationList,
    }),
  ),
);

const WagesWagesBookList = lazy(() =>
  import('./WagesBook/WagesBookList').then(module => ({
    default: module.WagesBookList,
  })),
);

const WagesWagesBookDetail = lazy(() =>
  import('./WagesBook/WagesBookDetail').then(module => ({
    default: module.WagesBookDetail,
  })),
);

const WagesWageCycleClosureList = lazy(() =>
  import('./WageCycleClosure/WageCycleClosureList').then(module => ({
    default: module.WageCycleClosureList,
  })),
);

const WagesWageCycleClosureForm = lazy(() =>
  import('./WageCycleClosure/WageCycleClosureForm').then(module => ({
    default: module.WageCycleClosureForm,
  })),
);

const WagesWageCycleClosureEmployeeListing = lazy(() =>
  import('./WageCycleClosure/WageCycleClosureEmployeeList').then(module => ({
    default: module.WageCycleClosureEmployeeList,
  })),
);

const WagesWageCycleClosureEmployee = lazy(() =>
  import('./WageCycleClosure/WageCycleClosure').then(module => ({
    default: module.WageCycleClosureEmployee,
  })),
);

const WorkerAttendanceListing = lazy(() =>
  import('../SiteWagesModule/WorkerAttendance/WorkerAttendanceListing').then(
    module => ({
      default: module.WorkerAttendanceListing,
    }),
  ),
);

const siteWagesRoutes = [
  {
    props: { exact: true, path: `/${SITE_WAGES}` },
    component: <SiteWagesSubmenu />,
  },

  //No siteID

  {
    props: { exact: true, path: `/${SITE_WAGES}` },
    component: <SiteWagesSubmenu />,
  },
  {
    props: { exact: true, path: `/${SITE_WAGES}/${ATTENDANCE}` },
    component: <WorkerAttendanceListing />,
  },
  {
    props: { exact: true, path: `/${SITE_WAGES}/${DAILY_REGISTER}` },
    component: <WagesDailyRegisterWorkHoursList />,
  },
  {
    props: { exact: true, path: `/${SITE_WAGES}/${DAILY_WAGES}` },
    component: <WagesDailyWagesVerificationList />,
  },
  {
    props: { exact: true, path: `/${SITE_WAGES}/${WAGES_BOOK}` },
    component: <WagesWagesBookList />,
  },
  {
    props: { exact: true, path: `/${SITE_WAGES}/${WAGE_CYCLE}` },
    component: <WagesWageCycleClosureList />,
  },

  //siteID

  {
    props: { exact: true, path: `/${SITE_WAGES}/:siteID` },
    component: <SiteWagesSubmenu />,
  },
  {
    props: { exact: true, path: `/${SITE_WAGES}/:siteID/${ATTENDANCE}` },
    component: <WorkerAttendanceListing />,
  },
  {
    props: { exact: true, path: `/${SITE_WAGES}/:siteID/${DAILY_REGISTER}` },
    component: <WagesDailyRegisterWorkHoursList />,
  },
  {
    props: { exact: true, path: `/${SITE_WAGES}/:siteID/${DAILY_WAGES}` },
    component: <WagesDailyWagesVerificationList />,
  },
  {
    props: { exact: true, path: `/${SITE_WAGES}/:siteID/${WAGES_BOOK}` },
    component: <WagesWagesBookList />,
  },
  {
    props: {
      exact: true,
      path: `/${SITE_WAGES}/:siteID/${WAGES_BOOK}/:workerID/${WAGES_BOOK_DETAIL}`,
    }, // add ID later
    component: <WagesWagesBookDetail />,
  },
  {
    props: { exact: true, path: `/${SITE_WAGES}/:siteID/${WAGE_CYCLE}` },
    component: <WagesWageCycleClosureList />,
  },
  {
    props: {
      exact: true,
      path: `/${SITE_WAGES}/:siteID/${WAGE_CYCLE}/form`,
    }, // add ID later
    component: <WagesWageCycleClosureForm />,
  },
  {
    props: {
      exact: true,
      path: `/${SITE_WAGES}/:siteID/${WAGE_CYCLE}/:wageCycleID`, // add ID later
    },
    component: <WagesWageCycleClosureEmployeeListing />,
  },
  {
    props: {
      exact: true,
      path: `/${SITE_WAGES}/:siteID/${WAGE_CYCLE}/:wageCycleID/${EMPLOYEE}/:workerID`, // add ID later
    },
    component: <WagesWageCycleClosureEmployee />,
  },
];

export default siteWagesRoutes;
